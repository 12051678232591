import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouse,
  faTrash,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import StripeCheckout from 'react-stripe-checkout';
import logo from '../images/logo.png';
import damjanImage from '../images/26.jpg';
import janaImage from '../images/4.jpg';
import '../styles/reservationform.css';
import '../styles/style.css';
import '../styles/footer.css';

const ReservationForm = () => {
  const { roomId } = useParams();
  const roomName = sessionStorage.getItem('roomName');
  const navigate = useNavigate();

  const userId = sessionStorage.getItem('userId');
  const storedToken = sessionStorage.getItem('token');
  const token = storedToken && storedToken.startsWith('Bearer ')
    ? storedToken.replace('Bearer ', '')
    : storedToken;

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reservationData, setReservationData] = useState(null);
  const [endDateErrorShown, setEndDateErrorShown] = useState(false);
  const [endDateKey, setEndDateKey] = useState(Date.now());
  const [loading, setLoading] = useState(false); // For showing loading spinner during payment processing

  const handleReservationSubmit = async (e) => {
    e.preventDefault();

    const data = {
      start_date: startDate,
      end_date: endDate,
    };

    try {
      const response = await fetch(
        `https://asprovaltam.com/users/${userId}/rooms/${roomId}/reservations`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            reservation: data,
          }),
        },
      );

      if (!response.ok) {
        throw new Error('Failed to submit reservation');
      }

      const reservation = await response.json();
      setReservationData(reservation.reservation);
      sessionStorage.setItem('reservationId', reservation.reservation.id);
      sessionStorage.setItem('startDate', reservation.reservation.start_date);
      sessionStorage.setItem('endDate', reservation.reservation.end_date);
      sessionStorage.setItem('totalPrice', reservation.reservation.total_price);

      console.log('Reservation submitted successfully');
    } catch (error) {
      console.error('Error submitting reservation:', error);
      toast.dismiss();
      toast.error('Failed to submit reservation. Please try again.');
    }
  };

  const sendTokenToBackend = async (stripeToken) => {
    const reservationId = sessionStorage.getItem('reservationId');

    try {
      setLoading(true); // Show loading spinner

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'X-Reservation-Id': reservationId,
        'X-Stripe-Token': stripeToken.id,
      };

      const response = await fetch(
        `https://asprovaltam.com/users/${userId}/rooms/${roomId}/reservations/${reservationId}/create_payment`,
        {
          method: 'POST',
          headers,
        },
      );

      if (response.ok) {
        navigate(
          `/users/${userId}/rooms/${roomId}/reservations/${reservationId}/success`,
        );
      } else {
        const error = await response.json();
        toast.dismiss();
        toast.error(`Error processing payment: ${error.error}`);
      }
    } catch (error) {
      toast.dismiss();
      toast.error('Network error. Please try again.');
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    const currentDate = new Date().toISOString().split('T')[0];

    if (selectedStartDate >= currentDate) {
      setStartDate(selectedStartDate);
      setEndDate('');
      setEndDateErrorShown(false);
    } else {
      toast.dismiss();
      toast.error('Please select a valid start date.');
    }
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    const currentDate = new Date().toISOString().split('T')[0];

    if (selectedEndDate === startDate) {
      setEndDateErrorShown(true);
      toast.dismiss();
      toast.error('End date must be after the start date.');
      setEndDateKey(Date.now());
    } else if (selectedEndDate >= currentDate && selectedEndDate > startDate) {
      setEndDate(selectedEndDate);
      if (endDateErrorShown) {
        setEndDateErrorShown(false);
      }
    } else if (selectedEndDate !== endDate && endDateErrorShown) {
      setEndDateErrorShown(false);
    } else {
      toast.dismiss();
      toast.error('Please select a valid end date.');
    }
  };

  const handleDeleteReservation = async () => {
    const reservationId = sessionStorage.getItem('reservationId');

    try {
      const response = await fetch(
        `https://asprovaltam.com/users/${userId}/rooms/${roomId}/reservations/${reservationId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error('Failed to delete reservation');
      }

      sessionStorage.removeItem('reservationId');
      sessionStorage.removeItem('startDate');
      sessionStorage.removeItem('endDate');
      sessionStorage.removeItem('totalPrice');

      setStartDate('');
      setEndDate('');
      setReservationData(null);

      console.log('Reservation deleted successfully');
      toast.success('Reservation deleted successfully');

      // window.location.reload();
    } catch (error) {
      console.error('Error deleting reservation:', error);
      toast.dismiss();
      toast.error('Failed to delete reservation. Please try again.');
    }
  };
  useEffect(() => {
    // Check if reservationData is null and perform cleanup actions
    if (!reservationData) {
      sessionStorage.removeItem('reservationId');
      sessionStorage.removeItem('startDate');
      sessionStorage.removeItem('endDate');
      sessionStorage.removeItem('totalPrice');
    }
  }, [reservationData]);

  const getImage = () => {
    if (roomName === 'Apartment Damjan') {
      return damjanImage;
    }
    if (roomName === 'Studio Jana') {
      return janaImage;
    }
    return null; // or a default image if needed
  };

  return (
    <div className="book-page">
      <div className="section-1-reservation">
        <div className="back-button-container">
          <Link to="/">
            <button
              type="button"
              className="home-button"
              aria-label="Go to home page"
            >
              <FontAwesomeIcon icon={faHouse} />
            </button>
          </Link>
        </div>
        <img className="logoA" src={logo} alt="logo.png" />
      </div>
      <div className="overlay">
        <div className="reservation-form-container">
          <h2 className="form-header">
            Room Name:
            {roomName}
          </h2>
          <div className="image-frame">
            <img
              className="room-image"
              src={getImage()}
              alt=" "
            />
          </div>
          <form onSubmit={handleReservationSubmit} className="form-actions">
            <div className="date-inputs">
              <div className="label-row">
                <label htmlFor="startDate" className="start-label">
                  Start Date:
                </label>
                <label htmlFor="endDate" className="end-label">
                  End Date:
                </label>
              </div>
              <div className="inputs-row">
                <input
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  className="start_date"
                  min={new Date().toISOString().split('T')[0]}
                  required
                />
                <input
                  key={endDateKey}
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  className="end_date"
                  min={startDate || new Date().toISOString().split('T')[0]}
                  required
                />
              </div>
            </div>
            <div className="button-container-rf">
              <button type="submit" className="button-reservation">
                Reserve
              </button>
            </div>
          </form>
        </div>

        {reservationData && (
          <div className="reservation-card1">
            <img
              src="https://media.istockphoto.com/id/1398814566/photo/interior-of-small-apartment-living-room-for-home-office.jpg?s=612x612&w=0&k=20&c=8clwg8hTpvoEwL7253aKdYAUuAp1-usFOacNR5qX-Rg="
              alt=""
              className="images"
            />
            <h3 className="card-title">Reservation Details</h3>
            <p>
              Reservation ID:
              {reservationData.id}
            </p>
            <p>
              Room Name:
              {reservationData.room_name}
            </p>
            <p>
              User Name:
              {reservationData.user_name}
            </p>
            <p>
              Start Date:
              {reservationData.start_date}
            </p>
            <p>
              End Date:
              {reservationData.end_date}
            </p>
            <p>
              Number of Nights:
              {reservationData.num_nights}
            </p>
            <p>
              Total Price: €
              {reservationData.total_price.toFixed(2)}
            </p>
            <div className="stripe_button">
              <StripeCheckout
                amount={reservationData.total_price * 100}
                token={sendTokenToBackend}
                currency="EUR"
                stripeKey="pk_live_IAFNp7jAESBZnFMNnGgsa8yR"
              >
                <button type="button" className="btn btn-primary">
                  Pay Now
                </button>
              </StripeCheckout>

              <button
                type="button"
                onClick={handleDeleteReservation}
                className="delete-button"
                aria-label="Delete"
              >
                <FontAwesomeIcon icon={faTrash} />
                <span className="delete-label">Delete</span>
              </button>
            </div>
            <div className="info_text">
              <FontAwesomeIcon icon={faCircleInfo} />
              <p>
                Please note that reservations will automatically be deleted if
                payment is not completed within five minutes.
              </p>
            </div>
          </div>
        )}

        {loading && (
          <div className="modal">
            <div className="modal-content">
              <img className="logoB" src={logo} alt="logo.png" />
              <p>Processing payment, please wait...</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReservationForm;
