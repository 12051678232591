import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Box from '@mui/material/Box';
import ReservationForm from './ReservationForm';
import Popup from './popup';
import RoomImages from './RoomImages';
import '../styles/MediaCard.css';
import '../styles/footer.css';
import ResponsiveAvailabilityCalendar from './AvailabilityCalendar';

const MediaCard = ({
  roomName,
  imageUrl,
  roomId,
}) => {
  const [showReservationForm, setShowReservationForm] = useState(false);
  const [openPopupId, setOpenPopupId] = useState(null); // Controls which popup is open
  const [showCalendar, setShowCalendar] = useState(false);
  const navigate = useNavigate();
  const cardRef = useRef(null);

  useEffect(() => {
    if (cardRef.current) {
      cardRef.current.style.opacity = 1;
    }
  }, []);

  const storedData = JSON.parse(sessionStorage.getItem('availabilityData'));
  const roomData = storedData?.available_dates_per_room[roomId];

  const handleDetailsClick = (popupId) => {
    // Open the popup for the clicked roomId, close others
    setOpenPopupId((prevId) => (prevId === popupId ? null : popupId));
  };

  const handleBookNowClick = () => {
    const userId = sessionStorage.getItem('userId');
    if (userId) {
      if (roomId) {
        sessionStorage.setItem('roomId', roomId);
        sessionStorage.setItem('roomName', roomName);
        navigate(`/users/${userId}/rooms/${roomId}/reservations`);
      } else {
        toast.error('Invalid roomId:', roomId);
      }
    } else {
      toast.dismiss();
      toast.error('Please Login or Register');
      navigate('/signin');
    }
  };

  const handleReservationFormClose = () => {
    setShowReservationForm(false);
    setShowCalendar(false);
  };

  const handleCalendarButtonClick = () => {
    setShowCalendar((prev) => !prev);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Card
        ref={cardRef}
        className="custom-card"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: { xs: '300px', sm: '400px', md: '620px' }, // Responsive width
          border: 0,
          borderRadius: '10px',
          gap: '5px',
          overflow: 'hidden',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: 'auto',
          backgroundColor: '#f7f7f6',
          position: 'relative',
          opacity: 0,
          transition: 'opacity 1s ease-in-out',
          boxShadow: '3px 9px 8px rgba(0, 0, 0, 0.1)',
          '&:hover': {
            boxShadow: '4px 12px 14px rgba(0, 0, 0, 0.2)',
            transform: 'translateY(-5px)',
          },
        }}
      >
        <div className="card-content-container" style={{ display: 'flex' }}>
          <CardMedia
            className="cardmedia-card"
            sx={{
              width: { xs: '100%', md: '255px' },
              height: 230,
              flexShrink: 0,
              '@media (min-width: 767px) and (max-width: 900px)': {
                flexDirection: 'column', // Set to column for widths between 767px and 900px
              },
            }}
            image={imageUrl}
            title={roomName}
          />
          <CardContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{
                '@media (max-width: 425px)': {
                  fontSize: '19px',
                  marginLeft: '20px',
                },
              }}
            >
              {roomData?.name}
              <Button sx={{ marginLeft: '20px', width: '25px', height: '25px' }} onClick={handleCalendarButtonClick}>
                <FontAwesomeIcon icon={faCalendar} style={{ width: '25px', height: '25px', color: '#5da0ff' }} />
              </Button>
            </Typography>
            <Box
              className="box"
              sx={{
                maxWidth: 310,
                maxHeight: 90,
                overflowY: 'auto',
                '@media (min-width:767px) and (max-width: 1439px)': {
                  maxWidth: '100%',
                },
              }}
            >
              <Typography
                variant="body2"
                color="text.primary"
                sx={{
                  backgroundColor: '#c9c9c9',
                  padding: '2px',
                  borderRadius: '2px',
                  marginBottom: '2px',
                  whiteSpace: 'pre-line',
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: 'bold',
                }}
              >
                <span>Available Dates</span>
                <span>Price</span>
              </Typography>

              {roomData?.available_dates.map(({ date, price }) => (
                <Typography
                  key={`${roomData.name}_${date}`}
                  variant="body2"
                  color="text.primary"
                  sx={{
                    backgroundColor: date % 2 === 0 ? '#dbd9d9' : '#e9e8e7',
                    padding: '2px',
                    borderRadius: '2px',
                    marginBottom: '3px',
                    whiteSpace: 'pre-line',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <span>{date}</span>
                  <span>
                    {price}
                    €
                  </span>
                </Typography>
              ))}
            </Box>

            <CardActions sx={{ marginTop: 'auto', flexDirection: 'row-reverse' }}>
              <Button
                id="bookBtn"
                size="small"
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  marginBottom: '-15px',
                  padding: '8px 16px',
                  border: 'none',
                  cursor: 'pointer',
                  fontStyle: 'italic',
                  borderRadius: '7px',
                  fontSize: '13px',
                  boxShadow: '20px 20px 20px rgba(239, 238, 238, 0.1)',
                }}
                onClick={() => handleBookNowClick(roomId)}
              >
                BOOK NOW
              </Button>
              <Button
                id="detailsBtn"
                sx={{
                  padding: '8px 16px',
                  marginRight: '15px',
                  marginBottom: '-15px',
                  fontSize: '13px',
                  borderRadius: '7px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  backgroundColor: 'rgb(93, 160, 255)',
                  color: 'rgb(244, 243, 243)',
                }}
                onClick={() => handleDetailsClick(roomId)}
              >
                DETAILS
              </Button>
            </CardActions>
            {openPopupId === roomId && (
              <Popup roomName={roomName} roomImages={RoomImages} openPopupId={openPopupId} roomId={roomId} onClose={() => setOpenPopupId(null)} />
            )}
          </CardContent>
        </div>

        {showCalendar && roomData && (
          <div className="calendar-content">
            <ResponsiveAvailabilityCalendar roomData={roomData} handleCalendarButtonClick={handleCalendarButtonClick} />
          </div>
        )}
      </Card>

      {showReservationForm && (
        <ReservationForm roomId={roomId} roomName={roomName} onClose={handleReservationFormClose} />
      )}
    </div>
  );
};

MediaCard.propTypes = {
  roomName: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  roomId: PropTypes.number.isRequired,
};

export default MediaCard;
